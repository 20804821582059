

import React,{useState, useEffect} from 'react'
import {
    Link
  } from "react-router-dom";

import LicenseOn from "../Images/On.png"
import LicenseOff from "../Images/Off.png"
import Axios from "../axios"
import Logo from "../Images/logo-white.png"
import Logo1 from "../Images/logo-dark.png"

const pkg = require('../../package.json');
const appVersion = pkg.version;


export default function AllProducts(props) {

    const getUrl = window.location.href

    

    const [drivers, setDrivers] = useState([])

    useEffect(() => {
        Axios.get(`instances`)
        .then(res => {
            setDrivers(res?.data?.data)
        })
        .catch(err => console.log(err,'err'))
    }, [getUrl])
    
    return (
        <div className="container">
             <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="custom--card text-center">
                    <h6>Welcome </h6> 
                    <img src={Logo} className="img-fluid navbar-logo light-logo" alt="" />
                    <img src={Logo1} className="img-fluid navbar-logo dark-logo" alt="" />
                    <div className="versionNumber">
                    <h7> -</h7> 
                    
                    </div>
                    <h7> Version {appVersion}</h7> 
                    
                  
                </div>
               
            </div>
            
        </div>
        
            <div className="row mt-3"></div>     
          
              
            <div className="row align-items-stretch">       
            {
                
               drivers?.map((el, index) => {
                    return(
               
                        <div className="col-md-4 mb-4">
                        <div className="custom--card small-card">
                                <div className="row mb-4 sim-row">
                                <div className="col-5">
                                        <img className="img-fluid" src={el?.VersionInfo?.banners?.high} alt="" />
                                    </div>
                                    <div className="col-7">
                                        <p className="title">{el?.CHModel}</p>
                                        <p className="subtitle">{el?.DeviceType}</p>
                                        <p className="subtitle sub-room">{el?.CHRoomName}</p>
                                    </div>
                                </div>
                                <Link
                                    to={{
                                        pathname: `/instance/${el?.ProductId}/${el?.InstanceID}`,
                                        state: { instance: el }
                                    }} >
                                 <span className="manage">Manage</span>
                               </Link>
                            <span className={el?.Active === true ? "instance-active" : 'instance-pasive'}>
                                <img src={LicenseOn} className="license-img l-on" alt="Activated" />
                                <img src={LicenseOff} className="license-img l-off" alt="Not Activated"/>
                                {el?.Active !== true ? "Not Licensed" : "Licensed"}
                            </span>
                            <div class="clear"></div>
                         

                        </div>
                    
                    </div>
                    )
                })
            }
            </div>
<div class="row-cols-1 mt-5" ></div>
           
                   
                    
                    {
             drivers.length === 0 ? 
                <>
               <div className="custom--card text-center">
                <div ><h6>No LCD Drivers are Loaded to the System</h6></div>
                </div>
            </>
            : null
            
    
        }
               

            
             {/* <div className="row">
                 {element?.Instances && element?.Instances?.map((el, index) => {
                     return(
                    
                            <div className="col-md-4">
                                <div className="custom--card">
                                        <div className="row">
                                            <div className="col-6">
                                                <p>{el?.CHModel}</p>
                                                <p>{el?.DeviceType}</p>
                                                <p>{el?.CHRoomName}</p>
                                            </div>
                                            <div className="col-6">
                                                <img className="img-fluid" src={versionInfo?.banners?.low} alt="" />
                                            </div>
                                        </div>
                                    <span className={el?.Active === true ? "instance-active" : 'instance-pasive'}>
                                        {el?.Active !== true ? "Not Licensed" : "Licensed"}
                                    </span>
                                 
                                    <Link
                                            to={{
                                                pathname: `/instance/${el?.ProductId}/${el?.InstanceID}`,
                                                state: { instance: el }
                                            }} >
                                         <span className="manage">Manage</span>
                                       </Link>
                                </div>
                            
                            </div>
                      
                     )
                 })}
             </div> */}
        </div>
    )
}
