import React, {useState,useEffect} from 'react'
import LicenseOn from "../Images/On.png"
import LicenseOff from "../Images/Off.png"

import {
    Link
  } from "react-router-dom";

import Axios from "../axios"

export default function Rooms(props) {
    const element = props?.location?.state?.element
    // const versionInfo = props?.location?.state?.element?._instances
    const getUrl = window.location.href

    const param = getUrl.split('/')

    const [rooms, setRooms] = useState([])

    useEffect(() => {
        Axios.get(`rooms/${param[param.length-1]}`)
        .then(res => {
            setRooms(res?.data?.data)
            console.log(res.data.data, 'roomso')
        })
        .catch(err => console.log(err,'err'))
    }, [getUrl])

    return (
        <div className="container">
            <div className="row mb-3">
                <div className="col-md-12">
                        <h1>{element?.Name}</h1>
                </div>
            </div>
            <div className="row align-items-stretch">
                 {rooms && rooms?.map((el, index) => {
                     return(
                            <div className="col-md-4 mb-4">
                                <div className="custom--card small-card">
                                        <div className="row mb-4 sim-row">
                                        <div className="col-5">
                                            <img className="img-fluid" src={el?.VersionInfo?.banners?.high} alt="" />
                                        </div>
                                            <div className="col-7">
                                                <p className="title">{el?.CHModel}</p>
                                                <p className="subtitle">{el?.DeviceType}</p>
                                                {/* <p className="room">{el?.CHRoomName}</p> */}
                                            </div>
                                        </div>

                                        <Link
                                            to={{
                                                pathname: `/instance/${el?.ProductId}/${el?.InstanceID}`,
                                                state: { instance: el }
                                            }} >
                                         <span className="manage">Manage</span>
                                       </Link>
                                    <span className={el?.Active === true ? "instance-active" : 'instance-pasive'}>
                                    <img src={LicenseOn} className="license-img l-on" />
                                <img src={LicenseOff} className="license-img l-off" />
                                        {el?.Active !== true ? "Not Licensed" : "Licensed"}
                                    </span>
                                    <div class="clear"></div>
                                </div>
                            </div>
                     )
                 })}
             </div>
        </div>
    )
}
