import React, {useState, useEffect} from 'react'
import axios from "axios"
import Axios from "../axios"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InstanceInfo from './shared/InstanceInfo';
import Loader from '../Utilities/Loader'
import Modal from "../Components/Modal"
import Login from "../Components/Login"
import LicenseOn from "../Images/On.png"
import LicenseOff from "../Images/Off.png"

export default function SingleProduct(props) {

    const getUrl = window.location.href
    const User = JSON.parse(localStorage.getItem('user'))
    const param = getUrl.split('/')

    const [tabIndex, setTabIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
    const [instanceData ,setInstanceData] = useState({})
    const [data, setData] = useState({})
    const [summary, setSummary] = useState({})
    const [hardwareId, setHardwareId] = useState({})

    const [licenseKey, setLicenseKey] = useState("")
    const [newLicenseKey, setNewLicenseKey] = useState("")
    const [licenseError, setLicenseError] = useState("")
    const [fn, setFunction] = useState({})
    const [key, setKey] = useState({})
    const [checkRawLicense, setCheckLicenseRaw] = useState({})
    const [error, setError] = useState(false)
    const [saveMsg, setSaveMsg] = useState("")
    const [isChanged, setIsChanged] = useState(false)
   
    const [isLoading, setIsLoading] = useState(true)

    const instance = props?.location?.state?.instance

    

    function saveLicenseKey(){
        axios.post(`${instanceData?.DriverRootApi}licence/key`,{licencekey:newLicenseKey},
        {
            headers: {
                "X-LC-Authorization":JSON.parse(localStorage.getItem("user"))?.data.token,
              },
        }
        )
        .then(res => {
            setSaveMsg("Licence key Saved, Activate Below")
          
        }).catch(err => {

            //setError(true)
            setIsOpen(true)
           
            if(User?.status !== 'success'){
                //setError(true)
                setIsOpen(true)
            }
        })
    }
    



    const activateLicense = (license) => {
      
        axios.post(`${instanceData?.DriverRootApi}licence/activate`)
        .then(res => {
            setLicenseError(res.data.data.data)
            setIsChanged(!isChanged)
            setSaveMsg("")
        }).catch(err => {
            console.log(err)
        })
    }

    const deActivateLicense = (liceense) => {
        axios.post(`${instanceData?.DriverRootApi}licence/deactivate`)
        .then(res => {
           
            setLicenseError(res.data.data.data)
            setLicenseKey("");
            console.log(res.data.data.data,'deactivate')

           
            setIsChanged(!isChanged)
        }).catch(err => {
            console.log(err)
        })
    }

    const checkLicense = () => {
        axios.get(`${instance?.DriverRootApi}licence/checkinraw`)
        .then(res => {
            setCheckLicenseRaw(res.data.data)
        })
        .catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        setError(false)
    
        Axios.get(`drivers/${param[param.length-1]}`)
        .then(res => {
            setInstanceData(res.data.data)
            //console.log(res.data.data,'instanceData')
            setIsLoading(false)
        }).catch(err => {
            console.log(err)
        })

        axios.get(`${instance?.DriverRootApi}`)
        .then(res => {
            setData(res.data.data)
        })
        .catch(err => {
            console.log(err)
        })

        //License
        axios.get(`${instance?.DriverRootApi}/licence`)
        .then(res => {
            // setData(res.data.data)
        })
        .catch(err => {
            console.log(err)
        })

        //summary
        axios.get(`${instance?.DriverRootApi}/licence/summary`)
        .then(res => {
            setSummary(res.data.data)
            setLicenseKey(res.data.data.LicenceKey)
        }).catch(err => {
            console.log(err,'err')
        })

        //HardwareId
        // axios.get(`${instance?.DriverRootApi}/licence/hardwareId`)
        // .then(res => {
        //     setHardwareId(res.data.data)
        // }).catch(err => {
        //     console.log(err,'err')
        // })
        // axios.get(`${instance?.DriverRootApi}/licence/key`)
        // .then(res => {
        //     setKey(res.data.data)
        // }).catch(err => {
        //     console.log(err,'err')
        // })


    },[licenseKey,isChanged])

    

    return (
        <div className="pb-5">
            {/* <h1>{instanceData?.CHRoomName}</h1> */}
            <div className="custom--card">
            <InstanceInfo instanceData={instanceData} />
            
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            {isLoading === true ? 
                    <Loader />
                    : 
                <>
                <TabList>
                    <Tab>License</Tab>
                    <Tab>Authentication</Tab>
                    <Tab>Function</Tab>
                </TabList>
                
                <TabPanel>
                <div className="row">
                            <div className="col-md-8" style={{paddingLeft:"20px"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6>Licence Type : Site Licence</h6>
                                        <p className={instanceData?.Active === true ? 'instance-active lic-state' : 'instance-pasive lic-state'}>
                                        <img src={LicenseOn} className="license-img l-on" alt="Activated" />
                                        <img src={LicenseOff} className="license-img l-off" alt="Not Activated" />
                                            {
                                                instanceData?.Active === true ? "License Activated" : "License Deactivated"
                                            }
                                        </p>

                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-12">
                                            <p className="subtitle">License Key (For demo input: PERP-0000)</p>

                                            </div>
                                            <div className="col-12 license-input">
{/* If Iset is True and DemoActive is False the the Licenc Key Edit Should be Disabled and the Save button Hidden. */}
{ instanceData?.Active === true ? 
            <>
            <input readOnly defaultValue={licenseKey} type="text" value={licenseKey}  onChange={(e) => setNewLicenseKey(e.target.value)} placeholder="License Key here" disabled />
            <button  onClick={saveLicenseKey} className="btn" style={{marginTop:"10px", visibility:"hidden"}}>

               Update

            </button>
            <h6>NB: Licence Key cannot be changed while driver is activated</h6>
        </>
        :
        <>
        
            <input defaultValue={licenseKey} type="text"   onChange={(e) => setNewLicenseKey(e.target.value)} placeholder="License Key here" />
            <button  onClick={saveLicenseKey} className="btn" style={{marginTop:"10px"}}>
                Update
            </button>
        </>

}
                                                
                                                {/* {summary} */}
                                           
                                                <p style={{color:"red"}}>
                                                            {
                                                                instanceData?.Active !== true ? saveMsg : null
                                                            }
                                                </p>
                                        
                                            {error === true ? <p style={{color:"red"}}>Please log in to activate this license</p> : ""}
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="col-md-12">
                                    <div className="col-12">
                                            <p className="subtitle">License Activation</p>

                                            </div>
                                        {
                                            instanceData?.Active !== true ? 
                                            <button onClick={(e) => activateLicense(licenseKey)}  className="btn btn-primary" >Activate</button>
                                            : 
                                            <button onClick={(e) => deActivateLicense(licenseKey)}  className="btn btn-warning" >De-Activate</button>

                                            }

                                    </div>
                                     <span className="error mt-4">
                                     {
                                         licenseError && licenseError
                                     }
                                 </span>
                                </div>
                            </div>

                        </div>

                </TabPanel>
                <TabPanel>
                    <h6>No Authentication Options Supported</h6>
                </TabPanel>
                <TabPanel>
                    <h6>No Functions supported</h6>

                </TabPanel>
                </>
            }

            </Tabs>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                  <Login closeModal={() => setIsOpen(false)}/>
            </Modal>

            </div>
        </div>
        
    )
}
