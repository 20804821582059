import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Sidebar from "../Components/Sidebar"
import Modal from "./Modal"
import Login from "./Login"
import Logo from "../Images/logo-white.png"
import RefreshIcon from "../Images/refresh.png"
import LoginIcon from "../Images/login.png"


const BUTTON_WRAPPER_STYLES = {
    position: 'relative',
    zIndex: 1
  }
  
  const OTHER_CONTENT_STYLES = {
    position: 'relative',
    zIndex: 2,
    backgroundColor: 'red',
    padding: '10px'
  }

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));




export default function Navbar(props) {
    const {themeMode} = props;
    //console.log(themeMode)
    const [openSidebar, setOpenSidebar] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [theme, setTheme] = useState('dark')

    const classes = useStyles();

    const refreshDrivers = () => {
      window.location.href = `http://${window.location.host}`
    }

    const toggleTheme = () => {
      if(theme == 'dark'){
        setTheme('light')
      }else{
        setTheme('dark')
      }
    }
    

    const toggleSidebar = (condition) => {
        if(openSidebar){
            setOpenSidebar(false)    
        }else{
            setOpenSidebar(true)    
            
        }
        
    }
    return (
        <>
        <div className="row">
            <AppBar position="relative">
                <Toolbar>
                <div onClick={toggleSidebar} id="menuToggle">
                  <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                    {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                      <MenuIcon onClick={toggleSidebar} />
                    </IconButton> */}
                    <a href="http://www.lightingcontrol.co.uk/drivers" target="_blank" >  <img src={Logo} className="img-fluid navbar-logo" alt="" /></a>
                    <Typography variant="h6" className={classes.title}>
                    {/* Lighting Control */}
                    </Typography>
                    
                    <Button onClick={() => props?.switchTheme()} className="themeControl" color="inherit">{themeMode}</Button>
                    <Button onClick={() => setIsOpen(true)} color="inherit"><img src={LoginIcon} className="icon" /> <span> Login </span></Button>
                    <Button onClick={refreshDrivers} color="inherit"><img src={RefreshIcon} className="icon" /> <span> Refresh Drivers </span></Button>
                </Toolbar>
            </AppBar>
                <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                  <Login closeModal={() => setIsOpen(false)}/>
                </Modal>
        </div>
        { openSidebar === true ? <Sidebar /> : ''}
        </>
    )
}
