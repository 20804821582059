import React from 'react'
import ReactDom from 'react-dom'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
//   backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 1100000
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .4)',
  zIndex: 1000000
}

export default function Modal({ open, children, onClose }) {
  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} className="overlay" />
      <div style={MODAL_STYLES} className="popup">
        <button className="cross" onClick={onClose}><span className="first"></span><span className="second"></span></button>
        {children}
      </div>
    </>,
    document.getElementById('portal')
  )
}