import React from 'react'

export default function InstanceInfo(props) {
    const {instanceData} = props;

    //console.log(instanceData,'instanccee')
    return (
        <div className="row mb-4">
            <div className="col-md-4">
                <img className="img-fluid product-img" src={instanceData?.VersionInfo?.banners?.high} alt="" />
            </div>
            <div className="col-md-8">
                <div className="row">
                <h1> {instanceData?.CHInstanceName}</h1> 
                <p className="title"> {instanceData?.CHModel}</p>
                <div className="col-md-12 description">
                            Some Description about Product
                        </div>
                    <div className="content-holder">
                        <h6>ROOM: {instanceData?.CHRoomName}</h6>
                        <h6>DRIVERTYPE: {instanceData?.DeviceType}</h6>
                        <h6>Latest Version: {instanceData?.VersionInfo?.version}</h6>
                        <h6>Loaded Version : {instanceData?.LoadedVersion}</h6>
                    </div>
                    <div className="col-md-12 ">
                        <a className="btn" href={`http://www.lightingcontrol.co.uk/drivers/${instanceData?.ProductId}`} target="_blank" rel="noreferrer" style={{marginRight:"30px"}}> Product Link </a>
                        <a className="btn" href={instanceData?.VersionInfo?.banners?.low} target="_blank" rel="noreferrer"> Documentation Link </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
