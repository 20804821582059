import React, {useState} from 'react'
// import Axios from "../axios"
import Axios from "../axios"

export default function Login(props) {

    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [errorMessage ,setErrorMessage] = useState("")

    const {closeModal} = props
    const Logout = () => {
                setErrorMessage('Logout Successful')
                localStorage.setItem('user','{"status":"loggedout","data":{"token": "-"}}')
                setTimeout(function(){ 
                    closeModal(false); 
                }, 1500);
          
    }
    const authUser = (e) => {
        e.preventDefault();
        Axios.post(`auth/login`, {
            username: username,
            password:password
        }).then(res => {
            if(res.data.data.error){
                setErrorMessage(res?.data?.data?.error)

            }else{
                setErrorMessage('Login Successful')
                localStorage.setItem('user',JSON.stringify(res.data))
                setTimeout(function(){ 
                    closeModal(false); 
                }, 1500);
          
            }
        })
        .catch(err => {
            setErrorMessage('Something went wrong')
        })
    }

    return (
        <div className="login">
            <div class="container">
                    <div class="panel">

                        <form action="" method="post" onSubmit={(e) => authUser(e)}>

                        <div class="panel-group">
                            Username: admin, password: admin.
                        </div>

                        <div class="panel-group">
                            <input class="input" type="text" required onChange={(e) => setUsername(e.target.value)} />
                            <span class="border"></span>
                            <label>Username</label>
                        </div>

                        <div class="panel-group">
                            <input class="input" type="password" required  onChange={(e) => setPassword(e.target.value)}/>
                            <span class="border"></span>
                            <label>Password</label>
                        </div>

                        <button type="submit">Login</button>
                         <p className="text-center" style={{color:"red"}}>
                             {
                                errorMessage && errorMessage
                            }
                         </p>
                        
                        </form>

                        {/* <div class="panel-footer">
                            <p><a href="#">Forgotten password?</a></p>
                            <p>Not a member? <a href="#">Sign up now</a></p>
                        </div> */}
                        <button onClick={Logout} >Logout</button>
                        
                    </div>
            </div>


        </div>
    )
}
