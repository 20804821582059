import React,{useState, useEffect} from 'react'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

export default function Loader() {

    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: #2A2930;
        `;
  
    return (
      <div className="loader">
        {/* <ClipLoader color={color} loading={loading} css={override} size={150} /> */}

        <div class="loader-holder">
  <svg viewBox="0 0 100 100">
  <circle cx="50" cy="50" r="25" stroke-width="8" fill="none"/>
</svg>
</div>
      </div>
    );
}
