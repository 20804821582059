import React,{useState, useEffect} from 'react'
import Axios from "../axios"

import Login from "../Components/Login"
import Modal from "../Components/Modal"

export default function SideLoader() {
  const User = JSON.parse(localStorage.getItem('user'))

    const [file, setFile] = useState({ preview: "", raw: "" });
    const [filename, setFileName] = useState("");
    console.log(file.raw,'raww')
    const [error, setError] = useState(false)
    const [status,setStatus] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    const handleUpload = async e => {
        const formData = new FormData();
        formData.append("file", file.raw);
        Axios.post(
          `file/upload/${filename}`,
          formData,
          {
            headers: {
                "X-LC-Authorization":JSON.parse(localStorage.getItem("user"))?.data.token,
              },
        }
        ).then(res => {
            console.log(res.data)
            setStatus(res.data.status)
        }).catch(err => {
            console.log(err)
            setIsOpen(true)
        })
      };

    const handleChange = async e => {
      if(e.target.files.length !== 0)
      {
        if(e.target.files[0].name.includes('.pkg')){
          setError(false)
          setFile({      
              raw: e.target.files[0]
            });
          setFileName(e.target.files[0].name);
          if(User?.status === 'loggedout')
          { 
            setIsOpen(true)
          }
      }else{
          setError(true)
      }
   
      }else
      {
        setError(true)
        
      }
        
       
        
            
      };

    return (
      <div className="container">
      <div className="row mb-3">
          <div className="col-md-12">
                  <h1>Upload file</h1>
          </div>
      </div>

      <div className="row align-items-stretch">
        <div className="col-md-12">
       
       <div className="custom--card">
               <p style={{color:"red"}}>{error === true ? 'Please upload a file in pkg format': null} </p>
               <p style={{color: "yellow"}}>This feature disabled for Demo.</p>
               <input 
                    type="file" 
                    onChange={handleChange}
                />
                {
                  User?.status === 'success' ?
                  <button className="btn btn-primary" disabled>Upload</button>
                  :
                  <button onClick={() => setIsOpen(true)} className="btn btn-primary">Upload</button>

                }
                {
                  status && status === 'success' ? 
                  <p style={{color:'green'}}>File Upload status: {status}</p> 
                  : 
                  null
                }
                </div>
                </div>
                </div>

                <Modal open={isOpen} onClose={() => setIsOpen(false)} >
                  <Login closeModal={() => setIsOpen(false)}/>
            </Modal>

            </div>
    )
}
