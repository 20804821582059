import './App.scss';
import React, { useState, useEffect } from 'react'
import {
  Switch,
  Route,
  HashRouter,
  
} from "react-router-dom";
import Main from "./Components/Main"
import Products from './Components/Products';
import Rooms from './Components/Rooms';
import Navbar from './Components/Navbar';
import SingleProduct from './Components/SingleProduct';
import SideLoader from './Components/SideLoader';




function App() {

  const [theme, setTheme] = useState('dark')

  
  const toggleTheme = () => {
    if(theme === 'dark'){
      setTheme('light')
    }else{
      setTheme('dark')
    }
  }




  useEffect(() => {

  },[theme])

  return (
    <HashRouter>
    <div className="App container-fluid p-0" id={theme}>
    <Navbar themeMode={theme} switchTheme={() => toggleTheme()} />
     
    <div className="body" id={theme}>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/products"  component={Products} />
        <Route path="/products/:id"  component={Products} />
        <Route path="/room"  component={Rooms} />
        <Route path="/room/:id"  component={Rooms} />
        <Route path="/instance/:id" component={SingleProduct} />
        <Route path="/file" component={SideLoader} />
      </Switch>
    </div>
    </div>
    </HashRouter>
  );
}

export default App;
