import React, {useState, useEffect} from 'react'
import Axios from "../axios"
import { Link } from "react-router-dom"
import Login from "../Components/Login"
import Modal from "../Components/Modal"
import RoomsIcon from "../Images/rooms.png"
import ProductsIcon from "../Images/products.png"
import ToolsIcon from "../Images/tools.png"



export default function Sidebar() {
    const User = JSON.parse(localStorage.getItem('user'))

    const [drivers, setDrivers] = useState({})
    const [rooms, setRooms] = useState([])
    const [isOpenProducts, setOpenProducts] = useState(true)
    const [isOpenRooms, setOpenRooms] = useState(true)
    const [isOpenTools, setOpenTools] = useState(false)

    const toggleProducts = (condition) => {
        if(isOpenProducts){
            setOpenProducts(false)    
        }else{
            setOpenProducts(true)    
            
        }
        
    }
    const toggleRooms = (condition) => {
        if(isOpenRooms){
            setOpenRooms(false)    
        }else{
            setOpenRooms(true)    
            
        }
        
    }
    const toggleTools = (condition) => {
        if(isOpenTools){
            setOpenTools(false)    
        }else{
            setOpenTools(true)    
            
        }
        
    }

    useEffect(() => {
        Axios.get(`/drivers`)
        .then(res => {
            setDrivers(res.data.data)
        })
        .catch(err => console.log(err))

        Axios.get('/rooms')
        .then(res => {
            setRooms(res.data.data)
            //console.log(res.data.data, 'roomz')
        })
        .catch(err => {
            //console.log(err)
        })
    }, [])
    
    return (
        <div className="sidebar">
            <ul>
                <li>
                    <h3 style={{cursor:"pointer"}} onClick={toggleProducts}><img src={ProductsIcon} className="icon" /> Products</h3>
                    {}
                    {
                        isOpenProducts === true ? 
                        <ul>
                          {
                              drivers && drivers?.Products?.map((el, index) => {
                                  return(
                                      <li>
                                    
                                          <Link
                                          to={{
                                              pathname: `/products/${el?.ProductID}`,
                                              state: { element: el, platform:drivers?.Platform }
                                          }}
                                          >
                                            {el?.ProductID}
                                            </Link>
                                      </li>
                                  )
                              })
                          }
              </ul> 
                :
                null
                    }
                </li>
                { drivers.Platform == "CrestronHome" ? 
                   <>
                 
                <li>
                    <h3 style={{pointer:"cursor"}} onClick={toggleRooms}><img src={RoomsIcon} className="icon" alt="Rooms"/> Rooms</h3>
                    <ul>
                    {
                         
                        isOpenRooms === true ? 
                        rooms && rooms?.map((el, index) => {
                            return(
                                <li>
                                    {
                                      el?._instances?.length > 0 ?  
                                      <Link
                                      to={{
                                          pathname: `/room/${el?.RegistryId}`,
                                          state: { element: el, platform:drivers?.Platform }
                                      }}
                                      >
                                          {el?.Name}
  
                                          </Link>
                                      : null  
                                    }
                               
                                </li>
                            )
                        })
                        : 
                        null
                    }
               
                    
                
                    </ul>
                </li>
              <li>
              <h3 style={{pointer:"cursor"}} onClick={toggleTools}><img src={ToolsIcon} className="icon" alt="Tools" /> Tools</h3>
                    <ul>
                        { isOpenTools === true ? 
                        <li>
                     
                        <Link
                        to={{
                        pathname: `/file`,
                        //state: { element: el, platform:drivers?.Platform }
                        }}>
                            Sideloader

                        </Link>
                
                    
                        </li>
                        : null  
                    }
                    </ul>
              </li>
               </>
               :
               <>
                  
               </>

                }
            </ul>
        </div>
    )
}
